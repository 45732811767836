import { createContext, useContext, useMemo } from 'react';

type GlobalContextValue = Record<string, never>;

const GlobalContext = createContext<GlobalContextValue>({});

type Props = {
  children: React.ReactNode;
};

export function GlobalProvider(props: Props) {
  const { children } = props;

  const contextValue = useMemo(() => ({}), []);

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
}

export function useGlobalStore() {
  return useContext(GlobalContext);
}
