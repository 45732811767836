import Axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

import {
  HttpMethods,
  HttpMethodsFilteredByPath,
  RequestData,
  RequestParameters,
  ResponseData,
  UrlPaths,
} from '@/api/helper';
import { HARMO_API_BASE_URL } from '@/constant';

const client = Axios.create({
  baseURL: HARMO_API_BASE_URL,
  timeout: undefined,
  headers: {
    'Content-Type': 'application/json',
  },
});

type AxiosConfigWrapper<Path extends UrlPaths, Method extends HttpMethods> = Omit<
  AxiosRequestConfig,
  'url' | 'method' | 'params' | 'data'
> & {
  url: Path;
  method: Method & HttpMethodsFilteredByPath<Path> & string;
  params?: RequestParameters<Path, Method>;
  data?: RequestData<Path, Method>;
};

export const setAuthorizationHeader = (token: string) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const getAuthorizationHeader = () => client.defaults.headers.common.Authorization;

export const removeAuthorizationHeader = () => {
  delete client.defaults.headers.common.Authorization;
};

export const signInToCenterServer = async () => {
  // TODO: Implement this function
  const result = await fetchApi({
    url: '/ps/v1/legacy/session',
    method: 'post',
    data: {
      targetAccountId: '63219611-dd6d-4767-85df-4f63cfdc293d',
      notificationId: '6124f49a6d5346b1a2ac4e6d235065c4',
      deviceCategory: '10',
      optoutFields: '9223372036854776000',
      uid: '2',
      notificationToken: 'fwyGG_VYmDY:APA91bEpc8Dcw2Qa4hoz0w9QpRd5xCJKfEC8gNd1xxxxxxxxxxx',
    },
  }).then((res) => res.data);
  return result;
};

export function fetchApi<Path extends UrlPaths, Method extends HttpMethods>(config: AxiosConfigWrapper<Path, Method>) {
  return client.request<
    ResponseData<Path, Method>,
    AxiosResponse<ResponseData<Path, Method>>,
    AxiosConfigWrapper<Path, Method>['data']
  >(config);
}
