import Axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

import {
  HttpMethods,
  HttpMethodsFilteredByPath,
  RequestData,
  RequestParameters,
  ResponseData,
  UrlPaths,
} from '@/api/helper-cs';
import { HARMO_CENTER_API_BASE_URL } from '@/constant';

const centerClient = Axios.create({
  baseURL: HARMO_CENTER_API_BASE_URL,
  withCredentials: true,
  timeout: undefined,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

type AxiosConfigWrapper<Path extends UrlPaths, Method extends HttpMethods> = Omit<
  AxiosRequestConfig,
  'url' | 'method' | 'params' | 'data'
> & {
  url: Path;
  method: Method & HttpMethodsFilteredByPath<Path> & string;
  params?: RequestParameters<Path, Method>;
  data?: RequestData<Path, Method>;
};

export function fetchCenterApi<Path extends UrlPaths, Method extends HttpMethods>(
  config: AxiosConfigWrapper<Path, Method>,
) {
  return centerClient.request<
    ResponseData<Path, Method>,
    AxiosResponse<ResponseData<Path, Method>>,
    AxiosConfigWrapper<Path, Method>['data']
  >(config);
}
