import '@/i18n';
import '@/index.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

// import { registerSW } from 'virtual:pwa-register';
import App from '@/App';
import { APP_ENV, SENTRY_DSN } from '@/constant';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  maxValueLength: 1000,
  normalizeDepth: 5,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['warn', 'error'],
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

/* PWAを有効にする場合はコメントアウトを解除する
// Service Worker registration
registerSW({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onNeedRefresh() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOfflineReady() {},
});
*/
