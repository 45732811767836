import { Button } from 'react-daisyui';
import { IoMdCheckmarkCircle, IoMdCloseCircle, IoMdInformationCircle, IoMdWarning } from 'react-icons/io';

import { useShowDownloadHarmoToast } from '@/utils/showDownloadHarmoToast';

type Props = {
  message: React.ReactNode;
  buttonTitle?: React.ReactNode;
  secondButtonTitle?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onSecondClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  iconType?: 'warning' | 'info' | 'success' | 'error';
};

export function MessageScreen(props: Props) {
  const { message, buttonTitle, secondButtonTitle, onClick, onSecondClick, iconType = 'warning' } = props;

  const icon = {
    warning: <IoMdWarning className="w-12 h-12 mx-auto mb-2 text-warning" />,
    info: <IoMdInformationCircle className="w-12 h-12 mx-auto mb-2 text-info" />,
    success: <IoMdCheckmarkCircle className="w-12 h-12 mx-auto mb-2 text-success" />,
    error: <IoMdCloseCircle className="w-12 h-12 mx-auto mb-2 text-error" />,
  }[iconType];

  useShowDownloadHarmoToast();

  return (
    <div className="flex flex-col justify-center h-full mx-4 my-auto">
      <div className="flex flex-col gap-4 mb-40">
        <p className="p-4 py-8 text-base leading-loose text-center whitespace-pre-line bg-white rounded-3xl">
          {icon}
          <div>{message}</div>
        </p>
        {!!buttonTitle && !!onClick && (
          <Button color="primary" fullWidth className="text-base font-bold rounded-full" onClick={onClick}>
            {buttonTitle}
          </Button>
        )}
        {!!secondButtonTitle && !!onSecondClick && (
          <Button color="secondary" fullWidth className="text-base font-bold rounded-full" onClick={onSecondClick}>
            {secondButtonTitle}
          </Button>
        )}
      </div>
    </div>
  );
}
