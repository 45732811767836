/* eslint-disable camelcase */
import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import Axios from 'axios';

import { COGNITO_AUTH_BASE_URL, COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from '@/constant';

const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_USER_POOL_ID,
  ClientId: COGNITO_CLIENT_ID,
});

export async function signIn(username: string, password: string) {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const user = await new Promise<{
    accessToken: string;
    idToken: string;
    refreshToken: string;
    user: CognitoUser | null;
  }>((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(session) {
        resolve({
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
          user: userPool.getCurrentUser(),
        });
      },
      onFailure(error) {
        reject(error);
      },
    });
  }).catch(() => undefined);

  return user;
}

export async function signOut() {
  const user = userPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
}

export async function signInWithRefreshToken(username: string, refreshToken: string) {
  const refreshTokenObj = new CognitoRefreshToken({ RefreshToken: refreshToken });

  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const user = await new Promise<{
    accessToken: string;
    idToken: string;
    refreshToken: string;
    user: CognitoUser | null;
  }>((resolve, reject) => {
    cognitoUser.refreshSession(refreshTokenObj, (error, session) => {
      if (error || !session) {
        console.warn('Failed to refresh cognito session:', error);
        reject(error);
        return;
      }

      resolve({
        accessToken: session.getAccessToken().getJwtToken(),
        idToken: session.getIdToken().getJwtToken(),
        refreshToken: session.getRefreshToken().getToken(),
        user: userPool.getCurrentUser(),
      });
    });
  }).catch(() => undefined);

  return user;
}

export async function signInWithAuthorizationCode(code: string) {
  const client = Axios.create({
    baseURL: COGNITO_AUTH_BASE_URL,
  });
  const response = await client.post<{
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    token_type: string;
  }>('/oauth2/token', null, {
    params: {
      grant_type: 'authorization_code',
      client_id: COGNITO_CLIENT_ID,
      code,
      redirect_uri: `${window.location.origin}/auth/oauth2-callback`,
    },
  });
  const {
    access_token: accessToken,
    expires_in: expiresIn,
    id_token: idToken,
    refresh_token: refreshToken,
    token_type: tokenType,
  } = response.data;
  return {
    accessToken,
    expiresIn,
    idToken,
    refreshToken,
    tokenType,
  };
}

export function getUserNameFromToken(token: string) {
  if (!token) {
    return '';
  }
  const payload = token.split('.')[1];
  const decodedPayload = atob(payload);
  const data = JSON.parse(decodedPayload);
  return data['cognito:username'] || data['sub'];
}
