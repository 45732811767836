import { Buffer } from 'buffer';
import randomBytes from 'randombytes';
import { createHash } from 'sha256-uint8array';

import { fetchApi } from './client';
import { fetchCenterApi } from './client-cs';
import { type components } from './schemas';

type Gender = components['schemas']['Gender'];

export async function createAccount({
  accessToken,
  name,
  kana,
  birthDate,
  gender,
}: {
  accessToken: string;
  name: string;
  kana: string;
  birthDate: string;
  gender: Gender;
}) {
  const idm = generateIDM();
  const secretForDeviceRegistration = generateSecret();

  const [birthYear] = birthDate.split('-');

  // CenterServer ユーザー登録
  const did = await createCenterServerAccount({
    idm,
    secretForDeviceRegistration,
    birthYear,
    gender,
  });

  // backend-fs ユーザー登録
  const accountId = await fetchApi({
    url: '/ps/v1/accounts/v2/upgrade',
    method: 'post',
    data: {
      accessToken,
      name,
      kana,
      birthDate,
      gender,
      idm,
      did: +did,
      secret: secretForDeviceRegistration,
    },
  })
    .then((res) => res.data.accountId)
    .catch((err) => {
      console.error(err);
      return '';
    });

  if (!accountId) {
    throw new Error('Failed to upgrade account');
  }
  return accountId;
}

/**
 * バーチャルカード ID (UUID)を生成する
 * @returns IDm
 */
function generateIDM(): string {
  return crypto.randomUUID().replace(/-/g, '');
}

/**
 * 端末ログインパスワード(例えば、idm と乱数から生成した十分な長さのハッシュ値)を生成する 32byte 乱数をsha256ハッシュ化する
 * @returns ハッシュ化されたパスワード
 */
function generateSecret(): string {
  const values = randomBytes(32);
  const secret = createHash('sha256').update(values).digest('hex').substring(0, 32);
  return secret;
}

/**
 * バーチャルカード固有値(64 ビット 10 進表現)を生成する
 * @returns バーチャルカード固有値
 */
function generateRSV(): string {
  const value = Array.from(randomBytes(8))
    .map((e) => e.toString(16).padStart(2, '0'))
    .join('');
  const rsv = BigInt(`0x${value}`).toString(10);
  return rsv;
}

/**
 * ライト会員登録用ワンタイムトークンから導出した認証コードを返す
 * @param token SHA-256でハッシュ生成し、先頭から32バイト分を返す
 * @returns 認証コード
 */
function generateAuthCodeBy(token: string): string {
  const bytes = Buffer.from(token, 'utf8');
  const digest = createHash('sha256').update(bytes).digest();

  const authCode = Array.from(digest.subarray(0, 32))
    .map((e) => e.toString(16).padStart(2, '0'))
    .join('');

  return authCode;
}

/**
 * CenterServerへユーザー登録し、登録成功時に得られる did を返す
 * @returns did
 */
async function createCenterServerAccount({
  idm,
  secretForDeviceRegistration,
  birthYear,
  gender,
}: {
  idm: string;
  secretForDeviceRegistration: string;
  birthYear: string;
  gender: Gender;
}) {
  console.log('Creating CenterServer account');
  console.log('idm:', idm);
  console.log('secretForDeviceRegistration:', secretForDeviceRegistration);
  console.log('birthYear:', birthYear);
  console.log('gender:', gender);
  // Fetch registration token
  const registrationToken = await fetchCenterApi({
    url: '/personal/issueRegistrationToken',
    method: 'post',
  }).then((res) => res.data.token || '');

  // Wait for the registration token to propagate
  await new Promise((resolve) => setTimeout(resolve, 11000));

  // Request lite user registration
  const secretForLiteUser = generateSecret();
  const rsv = generateRSV();
  const authCode = generateAuthCodeBy(registrationToken);

  const temporaryDid = await fetchCenterApi({
    url: '/personal/register',
    method: 'post',
    data: {
      idm,
      secret: secretForLiteUser,
      rsv,
      authCode,
    },
  })
    .then((res) => res.data.did || '')
    .catch((err) => {
      console.error(err);
      return '';
    });

  if (!temporaryDid) {
    throw new Error('Failed to register lite user');
  }

  // Log in and update session cookies
  await fetchCenterApi({
    url: '/personal/enter',
    method: 'post',
    data: {
      idm,
      did: temporaryDid,
      secret: secretForLiteUser,
    },
  });

  // Update lite user birth year, gender, and confirm registration
  await fetchCenterApi({
    url: '/personal/updatePersonalInfo',
    method: 'post',
    data: {
      birthYear,
      sex: gender === 'male' ? 1 : 2,
      name: '0000', // Fixed value
    },
  });

  // Log in again to update session cookies
  await fetchCenterApi({
    url: '/personal/enter',
    method: 'post',
    data: {
      idm,
      did: temporaryDid,
      secret: secretForLiteUser,
    },
  });

  // Register the device with a separate secret
  const did = await fetchCenterApi({
    url: '/personal/registerDevice',
    method: 'post',
    data: {
      secret: secretForDeviceRegistration,
    },
  })
    .then((res) => res.data.did || '')
    .catch((err) => {
      console.error(err);
      return '';
    });

  if (!did) {
    throw new Error('Failed to register device');
  }

  return did;
}
