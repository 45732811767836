import { MessageScreen } from '@/components/MessageScreen';

function NotFound() {
  return (
    <MessageScreen
      iconType="error"
      message="Webページを読み込めませんでした"
      buttonTitle="再度読み込む"
      onClick={() => window.location.reload()}
    />
  );
}

export default NotFound;
