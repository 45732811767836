export const APP_ENV = import.meta.env.VITE_APP_ENV;
export const APP_TITLE = import.meta.env.VITE_APP_TITLE;
export const HARMO_PS_API_BASE_URL = import.meta.env.VITE_HARMO_PS_API_BASE_URL;
export const HARMO_API_BASE_URL = import.meta.env.VITE_HARMO_API_BASE_URL;
export const HARMO_CENTER_API_BASE_URL = import.meta.env.VITE_HARMO_CENTER_API_BASE_URL;
export const HARMO_OAUTH_BASE_URL = import.meta.env.VITE_HARMO_OAUTH_BASE_URL;
export const HARMO_CURRENT_BASE_URL = import.meta.env.VITE_HARMO_CURRENT_BASE_URL;
export const COGNITO_CLIENT_ID = import.meta.env.VITE_COGNITO_CLIENT_ID;
export const COGNITO_USER_POOL_ID = import.meta.env.VITE_COGNITO_USER_POOL_ID;
export const COGNITO_AUTH_BASE_URL = import.meta.env.VITE_COGNITO_AUTH_BASE_URL;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const SIGN_IN_WITH_GOOGLE_URL = `${COGNITO_AUTH_BASE_URL}/oauth2/authorize?identity_provider=Google&redirect_uri=${HARMO_CURRENT_BASE_URL}/auth/oauth2-callback&response_type=CODE&client_id=${COGNITO_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid`;
export const SIGN_IN_WITH_APPLE_URL = `${COGNITO_AUTH_BASE_URL}/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${HARMO_CURRENT_BASE_URL}/auth/oauth2-callback&response_type=CODE&client_id=${COGNITO_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid`;
